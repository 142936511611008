import { AnimatePresence } from 'framer-motion';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ComponentPropsWithoutRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { z } from 'zod';
import { useZodForm } from '~/hooks/useZodForm';
import { DeveloperLogin } from '~/modules/account/ui/DeveloperButtons';
import { InputField } from '~/modules/ui/fields/input-field';
import {
  AuthViewContentHeader,
  AuthViewDescription,
  AuthViewTitle,
} from '~/modules/ui/layouts/auth-view';
import { Button, SubmitButton } from '~/modules/ui/primitives/button';
import { Form } from '~/modules/ui/primitives/form';
import {
  ArrowRightIcon,
  GoogleIcon,
  QuickbooksIcon,
  XeroIcon,
} from '~/modules/ui/primitives/icon';
import { m } from '~/modules/ui/primitives/motion-provider';
import { VStack } from '~/modules/ui/primitives/stack';
import { first } from '~/utils/first';
import { requiredStringSchema } from '~/utils/validation';

type Provider = 'xero' | 'google' | 'quickbooks';

function ProviderIcon(props: { provider: Provider }) {
  switch (props.provider) {
    case 'google':
      return <GoogleIcon />;
    case 'xero':
      return <XeroIcon />;
    case 'quickbooks':
      return <QuickbooksIcon />;
  }
}

interface ProviderButtonProps extends ComponentPropsWithoutRef<typeof Button> {
  provider: Provider;
}

function ProviderButton({ children, provider, ...props }: ProviderButtonProps) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      onClick={async () => {
        setLoading(true);
        await signIn(provider, {
          callbackUrl: first(router.query.callbackUrl) || '/org',
        });
      }}
      status={loading ? 'loading' : undefined}
      {...props}
    >
      <ProviderIcon provider={provider} />
      <span className="grow text-left">{children}</span>
      <ArrowRightIcon className="" />
    </Button>
  );
}

export function LoginOptions() {
  return (
    <>
      <VStack gap="2">
        <ProviderButton provider="google">Google</ProviderButton>
        <ProviderButton intent="secondary" provider="xero">
          Xero
        </ProviderButton>
        <ProviderButton intent="secondary" provider="quickbooks">
          QuickBooks Online
        </ProviderButton>
      </VStack>

      <ContinueWithEmail />

      <DeveloperLogin />
    </>
  );
}

function ContinueWithEmail() {
  const form = useZodForm({
    schema: z.object({
      email: requiredStringSchema.toLowerCase().email(),
    }),
    defaultValues: {},
  });
  const router = useRouter();

  // watch email value
  const email = useWatch({ control: form.control, name: 'email' });

  return (
    <Form
      form={form}
      onSubmit={async (values) => {
        await signIn<'email'>('email', {
          email: values.email,
          callbackUrl: first(router.query.callbackUrl) || '/org',
          redirect: false,
        });
        await router.push('/auth/verify');
      }}
    >
      <VStack gap="2">
        <InputField
          name="email"
          type="email"
          label="Or continue with email"
          placeholder="name@work-email.com"
          data-test-id="email-step-input"
        />

        <div className="relative">
          <AnimatePresence mode="sync">
            {!!email && (
              <m.div
                initial={{ height: 0, scaleY: 0.7, opacity: 0 }}
                animate={{ height: 'auto', scaleY: 1, opacity: 1 }}
                exit={{ height: 0, opacity: 0, scaleY: 0.7 }}
                transition={{ duration: 0.5, type: 'spring' }}
                // Positioned absolute to prevent layout shifts
                className="absolute"
              >
                <SubmitButton data-test-id="email-step-submit">
                  Get magic link
                </SubmitButton>
              </m.div>
            )}
          </AnimatePresence>
        </div>
      </VStack>
    </Form>
  );
}

export const LoginOrSignup = (props: {
  children: React.ReactNode;
}) => {
  const router = useRouter();

  if (Boolean(router.query.error)) {
    return (
      <AuthViewContentHeader>
        <AuthViewTitle>We&apos;re not quite ready for you</AuthViewTitle>
        <AuthViewDescription>
          Sorry, we&apos;re currently only accepting new accounts with work
          email addresses in the USA. If you you&apos;re receiving this message
          in error, please contact support.
        </AuthViewDescription>
      </AuthViewContentHeader>
    );
  }

  return (
    <VStack gap="10">
      {props.children}
      <LoginOptions />
    </VStack>
  );
};
